import React from "react"

/* Linkedin (noscript) */
const LinkedinNoscript: React.FC = () => {
    return (
        <noscript>
            <img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=2973724&fmt=gif" />
        </noscript>
    )
}
export default LinkedinNoscript
