import React from "react"

/* Google Tag Manager (noscript) */
const GTMNoscript: React.FC = () => {
    return (
        <noscript>
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5H2XMJL" height="0" width="0" style={{display:"none", visibility:"hidden"}}></iframe>
        </noscript>
    )
}
export default GTMNoscript