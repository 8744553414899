import React from "react"

/* Facebook Pixel (noscript) */
const FacebookNoscript: React.FC = () => {
    return (
      <noscript>
        <img height="1" width="1" style={{display:"none"}} src="https://www.facebook.com/tr?id=373642126784249&ev=PageView&noscript=1"/>
      </noscript>
    )
}
export default FacebookNoscript