import JobPosting from "../job-single"
import React from "react"
import { useSelector, shallowEqual } from "react-redux"
import classNames from "classnames"

interface ISavedJobs {
  show?: number
  className?: string
}

const JobsListingSaved: React.FC<ISavedJobs> = ({ show, className='' }) => {
  const sortByDate = (jobIxA, jobIxB) => {
    const dateA = new Date(selectedData[jobIxA].dateSaved)
    const dateB = new Date(selectedData[jobIxB].dateSaved)
    return dateB - dateA
  }

  const selectedData = useSelector(state => state.app.savedJobs, shallowEqual)

  return Object.keys(selectedData).length > 0 ? (
    <div className="card card-saved-jobs">
      <h3 className={classNames(className)}> Recently Saved </h3>
      {Object.keys(selectedData)
        .sort(sortByDate)
        .slice(0, show)
        .map(jobId => (
          <JobPosting job={selectedData[jobId]} key={jobId} recentlySaved={true}/>
        ))}
    </div>
  ) : null
}

export default JobsListingSaved
