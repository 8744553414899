import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import BookmarkOutlineSVG from "../components/SVGs/bookmark-outline.inline.svg"
import ShareSVG from "../components/SVGs/share.inline.svg"
import { useTranslation } from "react-i18next"
import JobsListingSaved from "../components/job-listing-saved"
import "../layouts/Primary/styles/skin/templates/_jobs.scss"
import WidgetRewards from "../components/widgets/rewards"
import SocialShare from "../components/socials"
import { Collapse } from "react-bootstrap"
import { toggleJob } from "../state/app"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import { hashString, isInBrowser, normalizeWorkplaceTypeName } from "../utils/helpers"

import OgImage from "../images/og-image-sng.jpg"

export interface IJobDetail {
  leverId: string
  positionOverview: any
  jobImage: {
    fluid: object
  }
}

export interface ILeverDetail {
  text: string
  applyUrl: string
  categories: {
    team: string
    location: string
  }
  salaryRange: {
    currency: string
    interval: string
    min: string
    max: string
  }
  salaryDescriptionPlain: string
  salaryDescription: string
  workplaceType: string
  lists: Array<ListItem>
}

export interface IJobAboutGuidewire {
  aboutHeading: string
  aboutText: any
}

interface IJobDetailProps {
  contentfulCareersJobDetail: IJobDetail
  lever: ILeverDetail
  allLever: {
    nodes: Array<ILeverDetail>
  }
  savedJobs: Array<any>
  allContentfulCareersCampaign: {
    nodes: {
      latestRolesLists: {
        searchParam: string
      }[]
    }[]
  }
}

interface ListItem {
  text: string
  content: string
}

interface ICareersHomePageContext {
  id: string
  slug: string
  locale: string
}

const JobPosting: React.FC<PageProps<IJobDetailProps, ICareersHomePageContext>> = ({
  pageContext,
  path,
  data,
  savedJobs,
  toggleJob,
}) => {
  const locale = pageContext.locale || "en-US"
  let metaImage = OgImage
  if (isInBrowser()) {
    metaImage = `https://${window.location.hostname + OgImage}`
  }
  const setMetaImage = data?.lever?.categories?.team === "Students & Graduates"
  const seoData = {
    title: data?.lever?.text,
    ogTitle: data?.lever?.text || "Guidewire Careers-Job post",
    description: data?.lever?.descriptionPlain || "Guidewire Careers - Guidewire job post",
    image: setMetaImage ? metaImage : null,
  }
  const { t } = useTranslation("strings")

  const [collapseSocial, setCollapseSocial] = useState(false)
  const [isSaved, setIsSaved] = useState(Boolean(savedJobs[data.lever.id]))

  const handleOnClick = (job: any) => {
    try {
      toggleJob(job)
      setIsSaved(Boolean(savedJobs[data.lever.id]))
      let eventName = Boolean(savedJobs[data.lever?.id]) ? "job_unsave" : "job_save"
      // @ts-ignore
      dataLayer.push({
        event: eventName,
        job_info: {
          job_title: job?.text,
          job_id: job?.id,
        },
      })
    } catch (e) {
      console.log(e)
      throw e
    }
  }
  const addDataLayerEvent = (job: any) => {
    // @ts-ignore
    dataLayer.push({
      event: "job_apply",
      job_info: {
        job_title: job?.text,
        job_id: job?.id,
      },
    })
  }

  useEffect(() => {
    setIsSaved(Boolean(savedJobs[data.lever.id]))
  }, [savedJobs, data.lever])

  let salaryFormatedCurency: string = data?.lever?.salaryRange?.currency
  let salaryInterval: string = data?.lever?.salaryRange?.interval

  switch (data?.lever?.salaryRange?.currency) {
    case "USD":
      salaryFormatedCurency = "$"
      break
    default:
      break
  }

  switch (data.lever.salaryRange?.interval) {
    case "per-year-salary":
      salaryInterval = "per year"
      break
    case "per-hour-wage":
      salaryInterval = "per hour"
    default:
      break
  }

  let formatAdditional = data.lever.additional
    .replace("<p>#featured</p>", "")
    .replace("<div>#featured</div>", "")
    .replace("<div><b>About Guidewire</b></div>", "<h3>About Guidewire</h3>")
    .replace("<div><br></div>", "")
    .replace("<div>", "<p>")
    .replace("</div>", "</p>")

  if (data.allContentfulCareersCampaign?.nodes?.length > 0) {
    data.allContentfulCareersCampaign.nodes.forEach(node => {
      if (node.latestRolesLists?.length > 0) {
        node.latestRolesLists.forEach(list => {
          formatAdditional = formatAdditional
            .replace(`<p>${list.searchParam}</p>`, "")
            .replace(`<div>${list.searchParam}</div>`, "")
        })
      }
    })
  }

  if (data?.lever?.salaryRange) {
    let salaryRangeInfo = `<div><h4>Salary Information</h4><span><strong>${t("Salary Range")}</strong></span>: ${
      salaryFormatedCurency + data.lever?.salaryRange?.min
    } - ${salaryFormatedCurency + data.lever?.salaryRange?.max} ${salaryInterval}<br /><br>
    ${data.lever?.salaryDescription}</div>`
    formatAdditional = formatAdditional.replace(
      "<h3>About Guidewire</h3>",
      salaryRangeInfo + "<h3>About Guidewire</h3>"
    )
  }

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      {/* Layout contains menus */}
      <Layout exploreDisable seoData={seoData}>
        <section className="hero hero-job">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <p className="sub-heading">Adventure's around the corner...</p>
                <h1 className="heading">{data.lever.text}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="job-detail">
          <div className="container job-container">
            <div className="row justify-content-between">
              <div className="col-lg-8">
                <ul className="list-unstyled job-detail-list">
                  <li>
                    <strong>{t("Department")}</strong>
                    <span>{data.lever.categories.team}</span>
                  </li>
                  <li>
                    <strong>{t("Location")}</strong>
                    <span>{data.lever.categories.location}</span>
                  </li>
                  <li>
                    <strong>{t("Location Type")}</strong>
                    <span>{normalizeWorkplaceTypeName(data?.lever?.workplaceType)}</span>
                  </li>
                </ul>
                <div className="cms-content job-detail-text">
                  <div dangerouslySetInnerHTML={{ __html: data.lever.description }} />
                  {data.lever.lists.map(list => (
                    <>
                      <h4>{list.text}</h4>
                      <ul dangerouslySetInnerHTML={{ __html: list.content }} />
                    </>
                  ))}
                  <div dangerouslySetInnerHTML={{ __html: formatAdditional }} />
                </div>
              </div>
              <div className="col-lg-4 sidebar">
                <div className="sticky">
                  <div className="card card-job-actions">
                    <a
                      id={`apply-${hashString(data.lever.text)}`}
                      href={data.lever.applyUrl}
                      onClick={() => addDataLayerEvent(data.lever)}
                      className="btn btn-primary btn-apply"
                      target="_blank"
                      rel="noopener"
                    >
                      {t("Apply now")}
                    </a>
                    <button
                      id={`save-later-${hashString(data.lever.text)}`}
                      className="btn btn-primary btn-icon"
                      title="Save this to My Jobs"
                      onClick={() => handleOnClick(data.lever)}
                    >
                      <BookmarkOutlineSVG className="icon-sprite" />
                      <span className="d-none d-lg-inline">
                        {isSaved ? "Remove from saved jobs" : t("Save for later")}
                      </span>
                    </button>
                    <button
                      className={`btn btn-primary btn-share d-lg-none${!collapseSocial ? " collapsed" : ""}`}
                      type="button"
                      data-toggle="collapse"
                      data-target="#job-share"
                      aria-expanded={collapseSocial}
                      aria-controls="job-share"
                      onClick={() => setCollapseSocial(!collapseSocial)}
                    >
                      <ShareSVG className="icon-sprite" />
                      <span className="sr-only">{t("Share this job")}</span>
                    </button>
                    <Collapse in={collapseSocial}>
                      <div className="share d-lg-flex flex-column job-posting-share" id="job-share">
                        <p>Share this role</p>
                        <SocialShare title={data.lever.text} />
                      </div>
                    </Collapse>
                  </div>
                  <JobsListingSaved leverJobs={data.allLever.nodes} show={1} className='job-listing-saved-job-posting'/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WidgetRewards />
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

const mapStateToProps = state => ({
  savedJobs: state.app.savedJobs,
})

const mapDispatchToProps = dispatch => ({
  toggleJob: job => dispatch(toggleJob(job)),
})

export default connect(mapStateToProps, mapDispatchToProps)(JobPosting)

export const query = graphql`
  query ($id: String!) {
    allLever {
      nodes {
        ...JobPosting
      }
    }
    lever(id: { eq: $id }) {
      ...JobPosting
    }
    allContentfulCareersCampaign(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        latestRolesLists {
          searchParam
        }
      }
    }
  }
`
