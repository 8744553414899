import React from "react"
import FacebookSVG from "../SVGs/facebook.inline.svg"
import LinkedinSVG from "../SVGs/linkedin.inline.svg"
import TwitterSVG from "../SVGs/twitter.inline.svg"
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton} from "react-share";
import "./style.scss"

interface ISocialShare {
  title: string
  url?: string
}

const style = {
  backgroundColor: "none"
}

const SocialShare: React.FC<ISocialShare> = ({title, url}) => {
  url = url ? url : (typeof window !== "undefined" ? window.location.href : "")
  return (
    <ul className="list-unstyled list-share-icons">
      <li>
        <FacebookShareButton
          className="facebook"
          url={url}
          title={title}
          style={style}>
          <FacebookSVG className="icon-sprite"/>
        </FacebookShareButton>
      </li>
      <li>
        <LinkedinShareButton
          className="linkedin"
          url={url}
          title={title}
          aria-label="linkedin"
          style={style}>
          <LinkedinSVG className="icon-sprite"/>
        </LinkedinShareButton>
      </li>
      <li>
        <TwitterShareButton
          className="twitter"
          url={url}
          title={title}
          style={style}>
          <TwitterSVG className="icon-sprite"/>
        </TwitterShareButton>
      </li>
    </ul>
  )
}

export default SocialShare
